import { api, helper } from ":mods";
import { ENDPOINTS } from "../const";
import { AStageModule, ModuleDetails, ModulesList, StageListDetails } from "../models";
import { modulesList, modulesDetails } from "../mocks";

const mock = false;
export async function getModulesList(stageID: number | string): Promise<ModulesList> {
  return api.getAuth(ENDPOINTS.module_list_with_stage_id + stageID).then((res) => res.data as ModulesList);
}
export async function getModuleDetails(moduleID: number | string): Promise<ModuleDetails> {
  if (moduleID === undefined) return undefined;
  if (helper.env.isDev && mock) return modulesDetails;
  return api.getAuth(ENDPOINTS.module_details_with_module_id + moduleID).then((res) => res.data as ModuleDetails);
}
export async function createModule(stageID: number | string, data: AStageModule): Promise<any> {
  return api.postAuth(ENDPOINTS.module_list_with_stage_id + stageID, data).then((res) => {
    return Promise.resolve(res.data.data);
  });
}
export async function updateModule(moduleID: number | string, data: AStageModule): Promise<any> {
  return api.putAuth(ENDPOINTS.module_details_with_module_id + moduleID, data).then((res) => res.data);
}
export async function deleteModules(ids: number[]): Promise<{ id: number; index: number; error?: string }[]> {
  return new Promise(async (res, rej) => {
    const result = [] as { id: number; index: number; error?: string }[];
    for (const id of ids) {
      const index = ids.indexOf(id);
      // result.push({ id, index });
      await api
        .delAuth(ENDPOINTS.module_details_with_module_id + id)
        .then((res) => {
          result.push({ id, index });
        })
        .catch((err) => {
          result.push({ id, index, error: err.response.data.error });
        });
    }
    res(result);
  });
}
